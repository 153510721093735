<template>
  <admin>
    <page-header :back="{ to: { name: 'survey' }, text: trans('Survey') }"></page-header>
    <p v-show="isCreatingQuestion"><a @click="isCreatingQuestion = false">< Back</a></p>
    <div v-if="!isCreatingQuestion">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="mb-5">
          <v-card-title>Survey</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Title"
                  class="dt-text-field"
                  outlined
                  v-model="form.title"
                  :rules="rules.title"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Slug"
                  class="dt-text-field"
                  outlined
                  v-model="form.slug"
                  :rules="rules.slug"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="categories"
                  label="Categories"
                  item-text="name"
                  item-value="id"
                  v-model="form.category_id"
                  outlined
                  :rules="rules.category_id"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  name="input-7-1"
                  label="Description"
                  class="dt-text-area"
                  outlined
                  v-model="form.description"
                  :rules="rules.description"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-switch v-model="form.status" label="Status" outlined></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title>Questions Builder</v-card-title>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="12">
                <v-alert class="pa-0">
                  <div>Create questions by dragging and drop survey elements</div>

                  <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

                  <v-row align="center" no-gutters>
                    <v-col class="grow"> Page will be redirected to the builder </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="shrink">
                      <v-btn @click="toggleViewQuestion" color="primary">
                        <span v-if="surveyInfo === '' || surveyInfo === null"
                          >Open Survey Builder</span
                        >
                        <span v-else>Edit Survey Questions</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="d-flex justify-end mt-6">
          <v-btn
            large
            exact
            color="green darken-1"
            class="ma-1 white--text"
            :loading="loadingSave"
            :disabled="loadingSave || loadingSaveAndExit"
            @click="validate(false)"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save
          </v-btn>
          <v-btn
            large
            exact
            color="green lighten-1"
            class="ma-1 white--text"
            :loading="loadingSaveAndExit"
            :disabled="loadingSave || loadingSaveAndExit"
            @click="validate(true)"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save and Exit
          </v-btn>
          <v-btn large exact color="warning" class="ma-1 white--text" :to="{ name: 'survey' }">
            <v-icon left>mdi-close</v-icon>
            Cancel
          </v-btn>
        </div>
      </v-form>
    </div>
    <SurveyForm
      v-if="isCreatingQuestion"
      :surveyConfiguration="surveyInfo"
      :defaultSurvey="defaultSurvey"
      :title="form.title"
    />
  </admin>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SurveyForm from "../forms/SurveyForm.vue";
export default {
  components: {
    SurveyForm,
  },
  data() {
    return {
      form: {
        title: "",
        description: null,
        status: true,
        slug: "",
        type: "custom",
      },
      isCreatingQuestion: false,
      valid: false,
      rules: {
        title: [(value) => !!value || "Title is required"],
        slug: [(value) => !!value || "Slug is required"],
        category_id: [(value) => !!value || "Category is required"],
      },
      defaultSurvey: {},
      loadingSaveAndExit: false,
      loadingSave: false,
      surveyInfo: "",
    };
  },
  computed: {
    ...mapGetters({
      surveys: "survey/GET_SURVEY",
      categories: "survey/GET_SURVEY_CATEGORIES",
    }),
  },
  methods: {
    ...mapActions({
      findSurvey: "survey/find",
      updateSurvey: "survey/update",
      getSurveyCategories: "survey/getSurveyCategories"
    }),
    toggleViewQuestion() {
      this.isCreatingQuestion = true;
    },
    toggleCreateQuestion() {
      if (this.isCreatingQuestion === false) {
        if (this.form.title == null || this.form.title == "") {
          this.showSnackbar({
            text: "Please enter title to create survey questions.",
            timeout: 2000,
          });
          return;
        }

        this.isCreatingQuestion = true;
        // if(isValid) this.isCreatingQuestion = true;
      } else {
        this.isCreatingQuestion = false;
      }
    },
    validate(isExit) {
      const self = this;

      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      let surveyForm = window.localStorage.getItem(self.form.title) || self.surveyInfo;

      let formData = {
        name: this.form.title,
        slug: this.form.slug,
        questions_data: surveyForm !== "" || surveyForm !== null ? surveyForm : "",
        type: this.form.type,
        description: this.form.description,
        status: this.form.status,
        id: self.$route.params.id,
        category_id: this.form.category_id
      };

      this.loadingSave = true;

      if (isExit) {
        this.loadingSaveAndExit = true;

        self.updateSurvey(formData).then(function (data) {
          window.localStorage.removeItem(self.form.title);
          self.$router.push({ name: "survey" });
          self.loadingSave = false;
        });
      } else {
        this.loadingSave = true;

        self.updateSurvey(formData).then(function (data) {
          window.localStorage.removeItem(self.form.title);
          self.$router.go();
        });
      }
    },
    getSurveyInfo() {
      const self = this;
      self.surveyInfo = window.localStorage.getItem(self.surveys.name)
        ? window.localStorage.getItem(self.surveys.name)
        : JSON.stringify(self.surveys.questions_data);
    },
  },
  mounted() {
    const self = this;
    let id = this.$route.params.id;

    self.findSurvey(id).then(function (data) {
      self.form.slug = self.surveys.slug;
      self.form.title = self.surveys.name;
      self.form.type = self.surveys.type;
      self.form.description = self.surveys.description;
      self.form.status = self.surveys.status;
      self.surveyInfo = JSON.stringify(self.surveys.questions_data);
      self.form.category_id = self.surveys.category_id;
    });

    window.onbeforeunload = () => {
      window.localStorage.removeItem(this.form.title);
    };

    this.getSurveyCategories();
  },
  destroyed() {
    window.localStorage.removeItem(this.form.title);
  },
};
</script>
